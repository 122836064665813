import { template as template_ece8b35bde054297aa99235b9aa03c22 } from "@ember/template-compiler";
const FKText = template_ece8b35bde054297aa99235b9aa03c22(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
