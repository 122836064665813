import { template as template_72c6deb28a24413caf2c2003e96c668c } from "@ember/template-compiler";
const FKLabel = template_72c6deb28a24413caf2c2003e96c668c(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
