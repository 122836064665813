import { template as template_75523e51686949fc956c9e02f0ca0680 } from "@ember/template-compiler";
const FKControlMenuContainer = template_75523e51686949fc956c9e02f0ca0680(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
